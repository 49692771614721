export const state = () => ({
	darkmode: false,
})

export const getters = {
	
}

export const mutations = {
	SET_SETTINGS(state, payload){
		Object.assign(state, payload)
	},
	SET_DARKMODE(state, payload) {
		state.darkmode = !!payload
	},
}

export const actions = {
	
}
