export default function ({ route, store, redirect, $auth }) {
  return new Promise((resolve, reject) => {
    store.dispatch('auth/fetchUser').then(() => {
      if (!store.getters['auth/token']) {
        store.commit('auth/REDIRECTED', route.fullPath)
        redirect({ name: $auth.routes(store.state.auth.strategy).login, query: route.query })
      }
    }).finally(() => {
      resolve()
    })
  })
}
