const { _, fn, rand, number } = require('./imports')

function generator (defu, options = {})  {
  const thiz = {}

  thiz.value = []
  thiz.options = _.merge(defu, options)

  if (!thiz.options.mask) {
    thiz.options.regex.pattern = ''
    thiz.options.regex.replacer = ''
  }

  const mod = (divd, divs) => number.round(divd - (number.floor(divd / divs) * divs))

  thiz.digits = function (n) {
    thiz.value = rand.number({ size: n })

    return thiz
  }

  thiz.zeros = function (n) {
    fn.times(n, () => thiz.value.push(0))

    return thiz
  }

  thiz.append = function (n) {
    thiz.value.push(n)

    return thiz
  }

  thiz.verify = function (index, from) {
    let range = number.range(from || index, 1)

    if (from) {
      range = range.concat(number.range(index - from + 1, 1))
    }

    let ver = 0
    fn.times(index - 1, n => ver += thiz.value[n] * range[n])
    ver = 11 - (mod(ver, 11))
    thiz.value.push(ver >= 10 ? 0 : ver)

    return thiz
  }

  thiz.get = () => thiz.value.join('').replace(thiz.options.regex.pattern, thiz.options.regex.replacer)

  return thiz
}

const cpf = opt => generator({
  regex: {
    pattern: /(\d{3})(\d{3})(\d{3})(\d{2})/,
    replacer: '$1.$2.$3-$4'
  }
}, opt).digits(9).verify(10).verify(11).get()

const cnpj = opt => generator({
  regex: {
    pattern: /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
    replacer: '$1.$2.$3.$4/$5'
  }
}, opt).digits(8).zeros(3).append(1).verify(13, 5).verify(14, 6).get()

module.exports = { cpf, cnpj }