const middleware = {}

middleware['auth'] = require('../middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['checkPromoDates'] = require('../middleware/checkPromoDates.js')
middleware['checkPromoDates'] = middleware['checkPromoDates'].default || middleware['checkPromoDates']

middleware['guest'] = require('../middleware/guest.js')
middleware['guest'] = middleware['guest'].default || middleware['guest']

middleware['searchURLParams'] = require('../middleware/searchURLParams.js')
middleware['searchURLParams'] = middleware['searchURLParams'].default || middleware['searchURLParams']

middleware['strategies'] = require('../middleware/strategies.js')
middleware['strategies'] = middleware['strategies'].default || middleware['strategies']

export default middleware
