const {
  get: _get, set: _set, keys, filter, flow,
  attempt, overArgs, bindKey, mixin, merge, times,
  isArray, isString, isError, castArray, random, range, floor, round
} = require('lodash')

const MODULES = {
  _: ['merge'], fn: ['times'], as: ['array'], is: ['array', 'string'], obj: ['keys'], rand: ['number'], number: ['range', 'floor', 'round']
}

for (const key in MODULES) {
  const methods = _get(MODULES, key)

  _set(MODULES, key, {})

  const onErrorDefault = (attempted, defu) => !isError(attempted) ? attempted : defu
  const toAttempt = mapper => args => flow([attempt, v => onErrorDefault(v, args)])(mapper, args)
  const toObject = overArgs((list, mapper) => Object.fromEntries(list.map(v => ([v, mapper(list, v)]))), [castArray, toAttempt])

  const source = toObject(methods, bindKey)

  _set(MODULES, key, mixin({ methods }, source))
}

MODULES._.merge = merge

MODULES.obj.keys = (object, opt = {}) => filter(keys(object), _get(opt, 'includes'))

MODULES.fn.times = times
MODULES.as.array = castArray
MODULES.is.array = isArray
MODULES.is.string = isString

MODULES.rand.number = overArgs((opt = {}) => range(opt.size).map(() => random(opt.min, opt.max)), opt => merge({ min: 0, max: 9, size: 1 }, opt))

MODULES.number.range = range
MODULES.number.round = round
MODULES.number.floor = floor

const _ = _get(MODULES, '_')
const fn = _get(MODULES, 'fn')
const as = _get(MODULES, 'as')
const is = _get(MODULES, 'is')
const obj = _get(MODULES, 'obj')
const rand = _get(MODULES, 'rand')
const number = _get(MODULES, 'number')

module.exports = { _, fn, as, is, obj, rand, number }