<template>
	<v-app id="admin-dash-layout">
		<drawer />
		<toolbar />
		<v-main>
			<v-container fluid class="fill-height d-flex flex-wrap flex-column align-start justify-start">
				<nuxt style="flex: 1; width: 100%;" />
			</v-container>
		</v-main>
		<confirmation-dialog />
	</v-app>
</template>

<script>

import Drawer from '~/components/admin/Drawer'
import Toolbar from '~/components/admin/Toolbar'

export default {
	name: 'DashboardLayout',
	components: {
		Drawer,
		Toolbar,
		ConfirmationDialog: () => import('~/components/admin/dialogs/Confirmation')
	},
	data: () => ({
		mode: 'light'
	}),
	middleware: 'auth',
	head(){
		return {
			title: 'Dashboard',
			htmlAttrs: {
				class: this.$store.state.settings.darkmode ? 'dark-mode' : 'light-mode'
			},
			meta: [{
				name: 'robots', content: 'noindex, nofollow'
			}]
		}
	},
	mounted(){
		this.$vuetify.theme.dark = this.$store.state.settings.darkmode
	}
}
</script>

<style lang="scss" src="~/assets/css/admin/index.scss" />
