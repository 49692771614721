export default function ({ route, store, redirect, $auth }) {
  return new Promise((resolve, reject) => {
    store.dispatch('auth/fetchUser').then(() => {
      if (store.getters['auth/token']) {
        let path = { name: $auth.routes(store.state.auth.strategy).home, query: route.query, hash: route.hash }

        if (store.getters['auth/redirected']) {
          path = store.getters['auth/redirected']
          store.commit('auth/REDIRECTED')
        }

        redirect(path)
      }
    }).finally(() => {
      resolve()
    })
  })
}
