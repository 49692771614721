import _merge from 'lodash/merge'

export const state = () => ({
	open: false,
	props: {},
	result: false,
	resolve: null
})

export const getters = {
	open: state => state.open,
	props: state => state.props,
	result: state => state.result
}

export const mutations = {
	OPEN (state, payload) {
		state.open = payload
	},
	PROPS (state, payload) {
		state.props = _merge({
			width: 400,
			persistent: true,
			closeable: false,
			title: null,
			text: 'Confirmar essa ação?',
			icon: 'info',
			iconColor: 'daccent',
			cancelText: 'Cancelar',
			confirmText: 'Confirmar'
		}, payload.props)
		state.resolve = payload.resolve
	},
	RESULT (state, payload) {
		state.result = payload
	}
}

export const actions = {
	choose ({ commit, state }, result) {
		commit('RESULT', result)
		commit('OPEN', false)
		state.resolve(result)
	},
	show ({ commit }, payload) {
		commit('PROPS', payload)
		commit('OPEN', true)
	}
}
