import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _31291b1c = () => interopDefault(import('../pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _37dcd1fc = () => interopDefault(import('../pages/cupom.vue' /* webpackChunkName: "pages/cupom" */))
const _a55dad14 = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _515dcac8 = () => interopDefault(import('../pages/perfil.vue' /* webpackChunkName: "pages/perfil" */))
const _0bc360b0 = () => interopDefault(import('../pages/regulamentos.vue' /* webpackChunkName: "pages/regulamentos" */))
const _ab3db538 = () => interopDefault(import('../pages/vencedores.vue' /* webpackChunkName: "pages/vencedores" */))
const _0ac59fe0 = () => interopDefault(import('../pages/admin/actions/index.vue' /* webpackChunkName: "pages/admin/actions/index" */))
const _7bb3a22e = () => interopDefault(import('../pages/admin/calculation.vue' /* webpackChunkName: "pages/admin/calculation" */))
const _1a20a09b = () => interopDefault(import('../pages/admin/commands/index.vue' /* webpackChunkName: "pages/admin/commands/index" */))
const _151b353c = () => interopDefault(import('../pages/admin/cpf-block/index.vue' /* webpackChunkName: "pages/admin/cpf-block/index" */))
const _56a93f34 = () => interopDefault(import('../pages/admin/dashboard.vue' /* webpackChunkName: "pages/admin/dashboard" */))
const _68c6aa8a = () => interopDefault(import('../pages/admin/dispatches/index.vue' /* webpackChunkName: "pages/admin/dispatches/index" */))
const _68a00b6b = () => interopDefault(import('../pages/admin/email-templates/index.vue' /* webpackChunkName: "pages/admin/email-templates/index" */))
const _a84760bc = () => interopDefault(import('../pages/admin/exports/index.vue' /* webpackChunkName: "pages/admin/exports/index" */))
const _55b1ca8e = () => interopDefault(import('../pages/admin/faq/index.vue' /* webpackChunkName: "pages/admin/faq/index" */))
const _3987b823 = () => interopDefault(import('../pages/admin/indexes/index.vue' /* webpackChunkName: "pages/admin/indexes/index" */))
const _411ea08a = () => interopDefault(import('../pages/admin/invalid-prizes/index.vue' /* webpackChunkName: "pages/admin/invalid-prizes/index" */))
const _448f490e = () => interopDefault(import('../pages/admin/invoices/index.vue' /* webpackChunkName: "pages/admin/invoices/index" */))
const _3de2444f = () => interopDefault(import('../pages/admin/ips/index.vue' /* webpackChunkName: "pages/admin/ips/index" */))
const _4134eab2 = () => interopDefault(import('../pages/admin/permissions/index.vue' /* webpackChunkName: "pages/admin/permissions/index" */))
const _22889118 = () => interopDefault(import('../pages/admin/prizes/index.vue' /* webpackChunkName: "pages/admin/prizes/index" */))
const _750e6f7d = () => interopDefault(import('../pages/admin/registrations/index.vue' /* webpackChunkName: "pages/admin/registrations/index" */))
const _93288428 = () => interopDefault(import('../pages/admin/regulations/index.vue' /* webpackChunkName: "pages/admin/regulations/index" */))
const _8dcae19a = () => interopDefault(import('../pages/admin/statistics.vue' /* webpackChunkName: "pages/admin/statistics" */))
const _0bfd1d6c = () => interopDefault(import('../pages/admin/tickets/index.vue' /* webpackChunkName: "pages/admin/tickets/index" */))
const _40a869a6 = () => interopDefault(import('../pages/admin/tokens/index.vue' /* webpackChunkName: "pages/admin/tokens/index" */))
const _374bc5fe = () => interopDefault(import('../pages/admin/total-tickets/index.vue' /* webpackChunkName: "pages/admin/total-tickets/index" */))
const _c4e095aa = () => interopDefault(import('../pages/admin/users/index.vue' /* webpackChunkName: "pages/admin/users/index" */))
const _5ea2ae37 = () => interopDefault(import('../pages/admin/winners/index.vue' /* webpackChunkName: "pages/admin/winners/index" */))
const _f63171a0 = () => interopDefault(import('../pages/admin/winners-draw/index.vue' /* webpackChunkName: "pages/admin/winners-draw/index" */))
const _0532c5ca = () => interopDefault(import('../pages/auth/forgot.vue' /* webpackChunkName: "pages/auth/forgot" */))
const _056143dc = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _9535acec = () => interopDefault(import('../pages/auth/register.vue' /* webpackChunkName: "pages/auth/register" */))
const _7ed45dec = () => interopDefault(import('../pages/admin/auth/forgot.vue' /* webpackChunkName: "pages/admin/auth/forgot" */))
const _e0842a5c = () => interopDefault(import('../pages/admin/auth/login.vue' /* webpackChunkName: "pages/admin/auth/login" */))
const _60a6113a = () => interopDefault(import('../pages/admin/dispatches/create.vue' /* webpackChunkName: "pages/admin/dispatches/create" */))
const _59d4a69a = () => interopDefault(import('../pages/admin/email-templates/create.vue' /* webpackChunkName: "pages/admin/email-templates/create" */))
const _111ef1b6 = () => interopDefault(import('../pages/admin/faq/create.vue' /* webpackChunkName: "pages/admin/faq/create" */))
const _3ec04a52 = () => interopDefault(import('../pages/admin/regulations/create.vue' /* webpackChunkName: "pages/admin/regulations/create" */))
const _3d81e531 = () => interopDefault(import('../pages/admin/tokens/create.vue' /* webpackChunkName: "pages/admin/tokens/create" */))
const _3c1b3af3 = () => interopDefault(import('../pages/admin/users/create.vue' /* webpackChunkName: "pages/admin/users/create" */))
const _3fb5e98e = () => interopDefault(import('../pages/admin/winners-draw/create.vue' /* webpackChunkName: "pages/admin/winners-draw/create" */))
const _4f0ef732 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _7845cce1 = () => interopDefault(import('../pages/admin/auth/reset/_token.vue' /* webpackChunkName: "pages/admin/auth/reset/_token" */))
const _47f2a3ac = () => interopDefault(import('../pages/admin/email-templates/edit/_id.vue' /* webpackChunkName: "pages/admin/email-templates/edit/_id" */))
const _7dfeacc4 = () => interopDefault(import('../pages/admin/faq/edit/_id.vue' /* webpackChunkName: "pages/admin/faq/edit/_id" */))
const _736c558b = () => interopDefault(import('../pages/admin/regulations/edit/_id.vue' /* webpackChunkName: "pages/admin/regulations/edit/_id" */))
const _f464bc28 = () => interopDefault(import('../pages/admin/users/edit/_id.vue' /* webpackChunkName: "pages/admin/users/edit/_id" */))
const _0d7715c7 = () => interopDefault(import('../pages/admin/winners-draw/edit/_id.vue' /* webpackChunkName: "pages/admin/winners-draw/edit/_id" */))
const _30fa1a48 = () => interopDefault(import('../pages/admin/actions/_id.vue' /* webpackChunkName: "pages/admin/actions/_id" */))
const _3a3c1fe3 = () => interopDefault(import('../pages/admin/dispatches/_id.vue' /* webpackChunkName: "pages/admin/dispatches/_id" */))
const _4fe1851c = () => interopDefault(import('../pages/admin/invalid-prizes/_id.vue' /* webpackChunkName: "pages/admin/invalid-prizes/_id" */))
const _2468e821 = () => interopDefault(import('../pages/admin/invoices/_id.vue' /* webpackChunkName: "pages/admin/invoices/_id" */))
const _b9a7aefa = () => interopDefault(import('../pages/admin/ips/_ip.vue' /* webpackChunkName: "pages/admin/ips/_ip" */))
const _18f906dc = () => interopDefault(import('../pages/admin/prizes/_id.vue' /* webpackChunkName: "pages/admin/prizes/_id" */))
const _337c2d25 = () => interopDefault(import('../pages/admin/registrations/_id.vue' /* webpackChunkName: "pages/admin/registrations/_id" */))
const _a7ad80ee = () => interopDefault(import('../pages/admin/validation/_prize.vue' /* webpackChunkName: "pages/admin/validation/_prize" */))
const _15ef9cbe = () => interopDefault(import('../pages/auth/reset/_token.vue' /* webpackChunkName: "pages/auth/reset/_token" */))
const _abcd8e48 = () => interopDefault(import('../pages/unsubscribe/_id.vue' /* webpackChunkName: "pages/unsubscribe/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/admin",
    component: _31291b1c,
    name: "admin"
  }, {
    path: "/cupom",
    component: _37dcd1fc,
    name: "cupom"
  }, {
    path: "/faq",
    component: _a55dad14,
    name: "faq"
  }, {
    path: "/perfil",
    component: _515dcac8,
    name: "perfil"
  }, {
    path: "/regulamentos",
    component: _0bc360b0,
    name: "regulamentos"
  }, {
    path: "/vencedores",
    component: _ab3db538,
    name: "vencedores"
  }, {
    path: "/admin/actions",
    component: _0ac59fe0,
    name: "admin-actions"
  }, {
    path: "/admin/calculation",
    component: _7bb3a22e,
    name: "admin-calculation"
  }, {
    path: "/admin/commands",
    component: _1a20a09b,
    name: "admin-commands"
  }, {
    path: "/admin/cpf-block",
    component: _151b353c,
    name: "admin-cpf-block"
  }, {
    path: "/admin/dashboard",
    component: _56a93f34,
    name: "admin-dashboard"
  }, {
    path: "/admin/dispatches",
    component: _68c6aa8a,
    name: "admin-dispatches"
  }, {
    path: "/admin/email-templates",
    component: _68a00b6b,
    name: "admin-email-templates"
  }, {
    path: "/admin/exports",
    component: _a84760bc,
    name: "admin-exports"
  }, {
    path: "/admin/faq",
    component: _55b1ca8e,
    name: "admin-faq"
  }, {
    path: "/admin/indexes",
    component: _3987b823,
    name: "admin-indexes"
  }, {
    path: "/admin/invalid-prizes",
    component: _411ea08a,
    name: "admin-invalid-prizes"
  }, {
    path: "/admin/invoices",
    component: _448f490e,
    name: "admin-invoices"
  }, {
    path: "/admin/ips",
    component: _3de2444f,
    name: "admin-ips"
  }, {
    path: "/admin/permissions",
    component: _4134eab2,
    name: "admin-permissions"
  }, {
    path: "/admin/prizes",
    component: _22889118,
    name: "admin-prizes"
  }, {
    path: "/admin/registrations",
    component: _750e6f7d,
    name: "admin-registrations"
  }, {
    path: "/admin/regulations",
    component: _93288428,
    name: "admin-regulations"
  }, {
    path: "/admin/statistics",
    component: _8dcae19a,
    name: "admin-statistics"
  }, {
    path: "/admin/tickets",
    component: _0bfd1d6c,
    name: "admin-tickets"
  }, {
    path: "/admin/tokens",
    component: _40a869a6,
    name: "admin-tokens"
  }, {
    path: "/admin/total-tickets",
    component: _374bc5fe,
    name: "admin-total-tickets"
  }, {
    path: "/admin/users",
    component: _c4e095aa,
    name: "admin-users"
  }, {
    path: "/admin/winners",
    component: _5ea2ae37,
    name: "admin-winners"
  }, {
    path: "/admin/winners-draw",
    component: _f63171a0,
    name: "admin-winners-draw"
  }, {
    path: "/forgot",
    component: _0532c5ca,
    name: "auth-forgot"
  }, {
    path: "/login",
    component: _056143dc,
    name: "auth-login"
  }, {
    path: "/register",
    component: _9535acec,
    name: "auth-register"
  }, {
    path: "/admin/forgot",
    component: _7ed45dec,
    name: "admin-auth-forgot"
  }, {
    path: "/admin/login",
    component: _e0842a5c,
    name: "admin-auth-login"
  }, {
    path: "/admin/dispatches/create",
    component: _60a6113a,
    name: "admin-dispatches-create"
  }, {
    path: "/admin/email-templates/create",
    component: _59d4a69a,
    name: "admin-email-templates-create"
  }, {
    path: "/admin/faq/create",
    component: _111ef1b6,
    name: "admin-faq-create"
  }, {
    path: "/admin/regulations/create",
    component: _3ec04a52,
    name: "admin-regulations-create"
  }, {
    path: "/admin/tokens/create",
    component: _3d81e531,
    name: "admin-tokens-create"
  }, {
    path: "/admin/users/create",
    component: _3c1b3af3,
    name: "admin-users-create"
  }, {
    path: "/admin/winners-draw/create",
    component: _3fb5e98e,
    name: "admin-winners-draw-create"
  }, {
    path: "/",
    component: _4f0ef732,
    name: "index"
  }, {
    path: "/admin/reset/:token?",
    component: _7845cce1,
    name: "admin-auth-reset-token"
  }, {
    path: "/admin/email-templates/edit/:id",
    component: _47f2a3ac,
    name: "admin-email-templates-edit-id"
  }, {
    path: "/admin/faq/edit/:id?",
    component: _7dfeacc4,
    name: "admin-faq-edit-id"
  }, {
    path: "/admin/regulations/edit/:id?",
    component: _736c558b,
    name: "admin-regulations-edit-id"
  }, {
    path: "/admin/users/edit/:id?",
    component: _f464bc28,
    name: "admin-users-edit-id"
  }, {
    path: "/admin/winners-draw/edit/:id",
    component: _0d7715c7,
    name: "admin-winners-draw-edit-id"
  }, {
    path: "/admin/actions/:id",
    component: _30fa1a48,
    name: "admin-actions-id"
  }, {
    path: "/admin/dispatches/:id",
    component: _3a3c1fe3,
    name: "admin-dispatches-id"
  }, {
    path: "/admin/invalid-prizes/:id?",
    component: _4fe1851c,
    name: "admin-invalid-prizes-id"
  }, {
    path: "/admin/invoices/:id",
    component: _2468e821,
    name: "admin-invoices-id"
  }, {
    path: "/admin/ips/:ip",
    component: _b9a7aefa,
    name: "admin-ips-ip"
  }, {
    path: "/admin/prizes/:id",
    component: _18f906dc,
    name: "admin-prizes-id"
  }, {
    path: "/admin/registrations/:id",
    component: _337c2d25,
    name: "admin-registrations-id"
  }, {
    path: "/admin/validation/:prize?",
    component: _a7ad80ee,
    name: "admin-validation-prize"
  }, {
    path: "/reset/:token?",
    component: _15ef9cbe,
    name: "auth-reset-token"
  }, {
    path: "/unsubscribe/:id?",
    component: _abcd8e48,
    name: "unsubscribe-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
