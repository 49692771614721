<template>
	<v-app id="admin-auth-layout">
		<div id="admin-auth-control" class="pa-4">
			<toolbar-controls/>
		</div>
		<nuxt style="flex: 1; width: 100%;" />
	</v-app>
</template>

<script>
export default {
	name: 'AdminAuthLayout',
	middleware: 'guest',
	components: {
		ToolbarControls: () => import('~/components/admin/ToolbarControls.vue'),
	},
	head(){
		return {
			htmlAttrs: {
				class: this.$store.state.settings.darkmode ? 'dark-mode' : 'light-mode'
			},
			meta: [{
				name: 'robots', content: 'noindex, nofollow'
			}]
		}
	},
	mounted(){
		this.$vuetify.theme.dark = this.$store.state.settings.darkmode
	}
}
</script>

<style lang="scss" src="~/assets/css/admin/index.scss" />
