const { fakerPT_BR: faker } = require('@faker-js/faker')
const { cpf, cnpj } = require('~/utils/@webcore/doc-generator')
const { states } = require('~/api/states')

const city = uf => faker.helpers.arrayElement(states.filter(s => !uf || s.value === uf).flatMap(s => s.cities))

const password = () => faker.internet.password({ length: 8, pattern: /[A-Za-z]/ }) + faker.helpers.arrayElement('!@#$%^&*(),.?":{}|<>'.split('')) + faker.helpers.fromRegExp('[0-9]')

const webcore = { cpf, cnpj, city, password }

export default (context, inject) => {
  inject('faker', { ...faker, webcore })
}
