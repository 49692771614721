import _castArray from 'lodash/castArray'

export default (context, inject) => {
  inject('tt', (key, ...params) => {
    let trans = key

    for (const k of _castArray(key)) {
      trans = context.app.i18n.t(k)

      if (trans !== `$vuetify.${k}` && trans !== k) {
        break
      }
    }

    return trans
  })
  inject('daytz', (date, fn, format = 'YYYY-MM-DD HH:mm:ss') => {
    const now = context.$dayjs().tz('America/Sao_Paulo', true)
    if (fn) {
      return date && now[fn](context.$dayjs.tz(date, format, 'America/Sao_Paulo', true))
    }
    return date ? context.$dayjs.tz(date, format, 'America/Sao_Paulo', true) : now
  })
}
