export const state = () => ({
  source: '',
  medium: '',
  campaign: ''
})

export const getters = {
  source: state => state.source,
  utm_source: state => state.source,
  medium: state => state.medium,
  utm_medium: state => state.medium,
  campaign: state => state.campaign,
  utm_campaign: state => state.campaign
}

export const mutations = {
  SET_UTM_SOURCE (state, value) {
    state.source = value || ''
  },
  SET_UTM_MEDIUM (state, value) {
    state.medium = value || ''
  },
  SET_UTM_CAMPAIGN (state, value) {
    state.campaign = value || ''
  }
}

export const actions = {
  setSource ({ commit }, value) {
    commit('SET_UTM_SOURCE', value)
  },
  setMedium ({ commit }, value) {
    commit('SET_UTM_MEDIUM', value)
  },
  setCampaign ({ commit }, value) {
    commit('SET_UTM_CAMPAIGN', value)
  }
}
