export const state = () => ({
  open: true
})

export const getters = {
  open: state => state.open
}

export const mutations = {
  OPEN (state, payload) {
    state.open = payload
  }
}

export const actions = {
  toggle ({ commit, state, ...rest }) {
    commit('OPEN', !state.open)
  }
}
