export const state = () => ({
  interval: null,
  start: null,
  show: false,
  now: null
})

export const getters = {
  diff: state => Math.abs(state.start - state.now),
  show: (state, getters) => getters.active && getters.diff >= 10,
  active: state => state.show
}

export const mutations = {
  NOW (state) {
    state.now = new Date().valueOf()
  },
  INTERVAL (state, payload) {
    state.interval = payload
  },
  SHOW (state, payload) {
    state.show = payload
  },
  START (state, payload) {
    state.start = payload
    if (!payload && state.interval) {
      state.now = null
      clearInterval(state.interval)
    }
  }
}

export const actions = {
  start ({ commit }, payload) {
    commit('SHOW', true)
    commit('START', payload)
    commit('INTERVAL', setInterval(commit, 50, 'NOW'))
  },
  finish ({ commit }) {
    commit('SHOW', false)
    commit('START', null)
  }
}
