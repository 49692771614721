import goTo from 'vuetify/es5/services/goto'

const waitForElm = (selector) => {
    return new Promise(resolve => {
        if(document.querySelector(selector))
            return resolve(document.querySelector(selector))

        const observer = new MutationObserver(mutations => {
            if(document.querySelector(selector)){
                setTimeout(() => resolve(document.querySelector(selector)), 200)
                observer.disconnect()
            }
        })

        observer.observe(document.body, { childList: true, subtree: true })
    })
}

export default function (to, from, savedPosition) {
  if(savedPosition){
    setTimeout(() => {
      goTo(savedPosition.y, { duration: 0, offset: 50, easing: 'linear' })
    }, 1000)
    return null
  }

  if(to.hash)
    return new Promise((r) => {
      if(document.querySelector(to.hash))
        r()
      else
        waitForElm(to.hash).then(r)
    }).then(() => {
      goTo(to.hash, { duration: 500, offset: 50, easing: 'linear' })
    })

  setTimeout(() => {
    goTo(0, { duration: 0, offset: 50, easing: 'linear' })
  }, 500)
  return null //{ x: 0, y: 0 }
}
