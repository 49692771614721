<template>
	<v-navigation-drawer :value="open" width="260" fixed app @input="onDrawerInput">
		<template #prepend>
			<v-list-item two-line class="px-1 ml-2" dense>
				<v-list-item-avatar class="mr-2">
					<v-img width="40" src="/webcore.png" class="border-fill"/>
				</v-list-item-avatar>
				<v-list-item-content v-if="$store.getters['auth/user']">
					WEBCORE
				</v-list-item-content>
			</v-list-item>
		</template>
		<v-divider />
		<v-list dense subheader class="text-capitalize mb-6">
			<template v-for="(item, i) in items">
				<v-subheader v-if="item.header" :key="`nav-${i}`" class="text-uppercase font-weight-bold" v-text="item.header" />
				<v-divider v-else-if="item.divider" :key="`nav-${i}`" />
				<v-list-item v-else-if="item.href" :key="`nav-${i}`" :to="item.href" :exact="item.exact" :ripple="false" exact-active-class="primary--text" active-class="primary--text">
					<template #default="{ active }">
						<v-list-item-action v-if="item.icon" class="mr-4 my-1">
							<v-icon size="22" :color="active ? 'primary' : undefined" v-text="_get($icons, item.icon)" />
						</v-list-item-action>
						<v-list-item-content>
							<v-list-item-title v-text="item.title" />
						</v-list-item-content>
					</template>
				</v-list-item>
				<v-list-item v-else-if="item.logout" :key="`nav-${i}`" :ripple="false" @click="$store.dispatch('auth/logout')">
					<v-list-item-action v-if="item.icon" class="mr-4 my-1">
						<v-icon size="22" v-text="$icons[item.icon]" />
					</v-list-item-action>
					<v-list-item-content>
						<v-list-item-title v-text="item.title" />
					</v-list-item-content>
				</v-list-item>
			</template>
		</v-list>
	</v-navigation-drawer>
</template>

<script>
import menu from '~/api/menu'
import _get from 'lodash/get'

export default {
	name: 'AdminDrawer',
	data: () => ({
		menus: menu
	}),
	computed: {
		title () {
			if (!this.$store.getters['auth/user'].type) {
				return ''
			}
			const type = this.$store.getters['auth/user'].type.toUpperCase()

			return `${this.$store.getters['auth/user'].name.split(' ')[0]} | ${type}`
		},
		items () {
			return this.menus
				.filter(m => !m.hidden)
				.filter(m => !m.permission || this.$store.getters['auth/permissions']?.some(p => {
					const [name, action] = m.permission.split(':')
					return p.permission === name && (!action || action === p.action)
				}))
		},
		drawer () {
			return this.$store.getters['drawer/open']
		},
		hasError () {
			return this.$nuxt?.nuxt?.err?.statusCode
		},
		open () {
			return this.drawer && !this.hasError
		}
	},
	methods: {
		_get,
		onDrawerInput (value) {
			if (!this.hasError) {
				this.$store.commit('drawer/OPEN', value)
			}
		}
	}
}
</script>
